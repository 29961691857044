import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Typeform, Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 1";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "thanks-for-coming-️"
    }}>{`Thanks for coming ❤️`}</h1>
    <p>{`Thanks for coming to my workshop. Hope you enjoyed it and learned a lot. I'm excited to see what you build with these skills :)`}</p>
    <p>{`If you can spare a moment, I'd love to hear your feedback.`}</p>
    <Typeform url="https://swizecteller.typeform.com/to/fSLwYM" mdxType="Typeform" />
    <p>{`No form? Reload the page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      